.unitsoftheday {
	.owl-carousel {
		@media(min-width: 576px) {
			/*	display: flex;
			width: unset;*/

			.unit-card-v1 {
				margin-bottom: 25px;
			}
		}
	}
}