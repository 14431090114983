.contact-form-v1 {
	height: 100%;

	.checkbox label {
		vertical-align: inherit;
	}

	.red {
		color: var(--color-red);
	}
}