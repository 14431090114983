.index-page-layout-v1 {
	margin-top: var(--page-margin-top);

	.contact-style {
		border: 1px solid var(--color-grey-normal);
		box-shadow: 0 0 8px 0 rgb(0 0 0 / 20%);
		border-radius: var(--border-radius);

		.contact-bott {
			display: flex;
			align-items: center;
			height: 100%;
		}
	}

	.newslatter-bl {
		@media(max-width:992px) {
			.news-image {
				width: 100%;
			}
		}

		@media(max-width:993px) {
			.news-image {
				width: 300px;

				.img-responsive {
					width: 100%;
					height: 100%;
					object-fit: cover;
					border-radius: var(--border-radius);
				}
			}
		}
	}

	.search-space {
		@media (min-width:993px) {
			padding-top: 65px;
		}
	}

	/*h2 {
		text-align: center;
	}*/

	.subtitle {
		text-align: center;
		text-indent: 0px;
	}

	.index-map {
		.leaflet-popup-content {
			margin: 0;
			padding: 15px;
		}
	}



	.highlights {
		display: flex;
		justify-content: space-between;

		@media (max-width:992px) {
			flex-wrap: wrap;
		}


		.highlight {
			text-align: center;
			color: var(--color-black);
			padding: 10px;
			position: relative;

			.icon {
				margin-bottom: 10px;
				margin-top: 20px;
				font-size: 36px;
				color: var(--color-primary);
				height: 54px;
			}

			.title {
				font-size: 20px;
				margin-bottom: 10px;
				font-weight: 600;
			}

			.text {
				font-size: 18px;
				padding-bottom: 30px;
				margin: 0 auto;

				@media(max-width: 1023px) {
					max-width: 300px;
				}

			}
		}
	}

	.unit-card-v1 {
		.flex-middle {
			min-height: 83px;
		}
	}
}