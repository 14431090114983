.static-view {
	margin-top: var(--page-margin-top);

	.theme-tile-wraper {
		border-radius: 5px;
		box-shadow: 0 7px 10px rgb(0 0 0 / 7%);
		overflow: hidden;
		position: relative;

		h4 {
			padding: 20px 25px;
		}

		a {
			position: absolute;
			left: 0;
			top: 0;
			height: 100%;
			width: 100%;
		}


	}

	.contact-map {
		height: 500px;
	}

	.inner-banner {

		.banner-logo {
			width: 200px;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, 50%);
		}

		&.aktivitaten {
			background-image: url(RESOURCE/img/inner-banner-aktivitaten.webp);

		}

		&.essen {
			background-image: url(RESOURCE/img/inner-banner-essen.webp);

		}

		&.familienurlaub {
			background-image: url(RESOURCE/img/banner_familienurlaub.webp);
		}

		&.search-banner {
			background-image: url(RESOURCE/img/banner_search.webp);
		}

		&.ferienimmobilie-kaufen {
			background-image: url(RESOURCE/img/ferienimmobilie_banner.webp);
		}

		&.vermieten {
			background-image: url(RESOURCE/img/banner_vermieten.webp);
		}

		&.contact {
			background-image: url(RESOURCE/img/banner_contact.webp);
		}

		&.ueber {
			background-image: url(RESOURCE/img/ueber_banner.webp);
		}

		&.eckernforde {
			background-image: url(RESOURCE/img/banner_eckernfoerde.webp);
		}
	}

	&.familienurlaub-v1 {
		img {
			width: 100%;
		}

		.single-unit {
			box-shadow: none !important;

			.img-content {
				.fav-icon {
					right: 0;
					left: auto !important;
				}
			}
		}

		.unit-card-v1 {
			margin-bottom: 0;

			.unit-container {
				.img-col {
					padding-top: 0;
				}
			}
		}
	}

	.eckernfoerde-theme-v1 {
		.col-md-6 {
			padding: 20px 20px
		}
	}

	.unit-list-section {
		.owl-dots {
			text-align: center;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-bottom: 15px;
		}

		.owl-nav {
			button {
				width: 48px;
				height: 48px;
				background-color: var(--color-primary);
				position: absolute;
				top: 52%;
				transform: translate(-50%, 0%);
				border-radius: 0 !important;
				left: 0 !important;
				color: var(--color-white);


				@media(max-width:767px) {
					width: 30px;
					height: 40px;
				}


				&:hover {
					&:before {
						color: var(--defaultColor);
					}
				}

				&.owl-next {
					right: 0;
					left: inherit !important;
					transform: translate(50%, 0%);

				}

				&:before {
					color: var(--whiteColor);

					@media(max-width:767px) {
						font-size: 24px;
					}

					font-weight: 300;
				}


				span {
					color: var(--whiteColor);
					font-size: 28px;
				}
			}
		}
	}


	.unit-list-section {
		.owl-nav {
			button {
				top: 45% !important;
			}
		}

		.single-unit {
			float: left;
			margin: 10px 3px;
			padding: 0;
			box-shadow: 0 3px 6px rgba(0, 0, 0, .16);

			@media (max-width:767px) {
				max-width: 450px;
				margin: 4px 3px;
				float: none;
			}

			.img-content {
				position: relative;

				@media (max-width:650px) {
					padding-top: 0;

					>img {
						width: 100%;
						height: 100%;
						object-fit: cover;
						position: absolute;
						top: 0;
						object-position: bottom;
					}

				}

				.fav-icon {
					position: absolute;
					cursor: pointer;
					left: 0;
					top: 0;
					margin: 3px;
					font-size: 24px;
					width: 35px;
					text-align: center;
					z-index: 2;

					.fa-heart {
						color: var(--color-white);
					}
				}
			}

			a {
				color: inherit;
				text-decoration: none;
			}

			.unit-dt {
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-bottom: 15px;

				@media (max-width:1200px) {
					flex-direction: column;
					justify-content: flex-start;
					align-items: flex-start;
				}
			}

			.unit-title-area {
				display: flex;
				align-items: center;
				justify-content: space-between;

				h3 {
					margin-top: 10px;
					font-size: 25px;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					font-weight: bold;

					&.unit-name {
						max-width: 250px;
					}
				}
			}



			.udetails {
				display: block;
				padding: 5px 15px 20px;
				position: relative;
				padding-bottom: 20px;

				.prop {
					padding-right: 4px;
					font-size: 14px;
				}

				p {
					-webkit-line-clamp: 3;
					-webkit-box-orient: vertical;
					overflow: hidden;
					display: -webkit-box;
				}


				.door {
					width: 10px;
					height: 15px;
					display: inline-block;
					background: no-repeat url(RESOURCE/img/door-smaller.png) 0 0;
					position: relative;
					top: 2px;
				}

			}
		}
	}

	.team-slider {
		.team-wraper {
			padding: 0 15px;
			text-align: center;

			.team-img {
				display: flex;
				justify-content: center;
				margin-bottom: 30px;

				img {
					width: 100%;
					border-radius: 50%;
					height: 250px;
					width: 250px;
					border: 6px solid var(--color-primary);
				}
			}

			.team-content {}
		}

		.owl-nav {
			button {
				position: absolute;
				top: 125px;
				transform: translateY(-125px);
				height: 50px;
				width: 50px;
				background: #ceb479;
				border-radius: 50%;

				span {
					display: none;
				}

				&:before {
					content: "";
					border: solid #fff;
					border-width: 0 2px 2px 0;
					display: inline-block;
					padding: 6px;
				}

				&.owl-next {
					transform: rotate(-45deg);
					-webkit-transform: rotate(-45deg);
					right: 0;
				}

				&.owl-prev {
					left: 0;
					transform: rotate(135deg);
					-webkit-transform: rotate(135deg);
				}
			}
		}
	}

	.team-placeloder {
		img {
			width: 100%;
		}
	}

	b,
	strong {
		font-weight: 700;
	}

	.eckernfoerde-img {
		max-width: 250px;
	}

	&.ferienobjekt-vermieten-v1 {
		.owl-dots {
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 0 auto;
			margin-top: 20px;

			@media (max-width: 767px) {
				margin: 0 auto;
			}

			.owl-dot {
				width: 15px;
				height: 15px;
				background: #b5b5b5;
				margin: 0 5px;
				border-radius: 100px;

				&.active {
					background: var(--color-primary);
				}
			}
		}

		.usp-desk {
			.cust-row {
				--bs-gutter-x: 1.5rem;
				--bs-gutter-y: 0;
				display: -webkit-box;
				display: flex;
				flex-wrap: wrap;
				margin-top: calc(-1* var(--bs-gutter-y));
				margin-right: calc(-0.5* var(--bs-gutter-x));
				margin-left: calc(-0.5* var(--bs-gutter-x));
			}

			.cust-row>* {
				flex-shrink: 0;
				width: 100%;
				max-width: 100%;
				padding-right: calc(var(--bs-gutter-x)* 0.5);
				padding-left: calc(var(--bs-gutter-x)* 0.5);
				margin-top: var(--bs-gutter-y);
			}

			@media (min-width: 768px) {
				.cust-col-md-4 {
					width: 33.33333333%;
				}
			}
		}


		.hide-mob {
			@media (max-width:992px) {
				display: none !important;
			}
		}

		.usp-mob {
			.feature-slide {}
		}

		.contact-detail {
			.contact-item {
				background-color: #fff;
				/*	border: 1px solid var(--color-grey-normal);*/
				transition: all .2s;
				height: 100%;
				/*	border-radius: var(--border-radius);*/

				.contact-img {
					width: 300px;
					height: 300px;
					overflow: hidden;
					border-radius: 50%;
					display: table;
					margin: 0 auto;
					border: 8px solid var(--color-primary);

					img {
						height: 100%;
						width: 100%;
						object-fit: cover;
						/*	border-radius: 100%;
						height: 300px;
						width: 300px;
						margin: 0 auto;
						display: table;*/
					}
				}

				/*&:hover {
					box-shadow: 0 0 8px 0 rgb(0 0 0 / 20%);
					transform: scale(1.015);
				}*/

				.contact-content {
					padding: 10px;
					text-align: center;
				}
			}
		}

		.highlights {
			display: flex;
			justify-content: space-between;

			@media (max-width:992px) {
				flex-wrap: wrap;
			}


			.highlight {
				text-align: center;
				color: var(--color-black);
				position: relative;
				background-color: #fff;
				border: 1px solid var(--color-grey-normal);
				transition: all .2s;
				height: 100%;
				border-radius: var(--border-radius);

				/*&:hover {
					box-shadow: 0 0 8px 0 rgb(0 0 0 / 20%);
					transform: scale(1.015);
				}*/

				.content {
					padding: 10px;
					text-align: center;
					min-height: 168px;
				}

				.icon {
					margin-bottom: 10px;
					margin-top: 20px;
					font-size: 36px;
					color: var(--color-primary);
					height: 54px;
				}

				.title {
					font-size: 20px;
					margin-bottom: 10px;
					font-weight: 600;
				}

				.text {
					font-size: 18px;
					margin: 0 auto;

					@media(max-width: 1023px) {
						max-width: 300px;
					}

				}

				.usp-img {
					max-width: 50px;
					margin: 0 auto;
					padding: 20px 0 0;
				}
			}
		}
	}
}