.search-page-layout-v1 {
	margin-top: var(--page-margin-top);


	.list-view {
		@media(max-width: 767px) {
			min-height: 750px;
		}
	}

	.sticky-map {
		position: sticky;
		top: 80px;
	}

	.search-form-area {
		display: flex;
		position: relative;
		-webkit-box-align: center;
		align-items: center;
		-webkit-box-pack: justify;
		justify-content: space-between;
		background-color: #fff;
		background-color: var(--color-white);
		/*border: 10px solid #eaeaea;*/
		border: 10px solid var(--color-white);
		flex-flow: wrap;
		/*border: 10px solid var(--color-grey-normal);*/
		border-radius: 8px;
		margin: 0;
		padding: 10px 70px 10px 0;
		box-shadow: 0 0 40px rgb(0 0 0 / 8%);

		.form-btn {

			display: flow-root;
			webkit-box-align: center;
			align-items: center;
			padding-right: 10px;
			right: 0;
			position: absolute;

			@media (max-width:992px) {
				width: 100%;
				padding-left: 12px;
				position: relative;
				margin-top: 6px;
			}

			.btn {
				width: 46px;
				height: 40px;
				align-items: center;
				display: flex;
				justify-content: center;
				font-size: 18px;

				@media (max-width:992px) {
					height: 40px;
					font-size: 20px;
					width: 100%;

				}
			}
		}

		@media(max-width: 992px) {
			padding: 10px;
			border: 0;
		}

		.form-group {
			width: 20%;
			padding: 9px 15px;
			border-right: 1px solid #f4f4f4;
			position: relative;


			@media (max-width:1024px) {
				width: 100%;
				border-bottom: 1px solid #f4f4f4;
				border-right: none;
				padding: 6px 12px;
			}

		}

		.guestbox {

			button {
				padding-left: 0;
			}

		}

		.datepicker-trigger {
			button {
				padding: 4px 8px;
				text-align: center;
			}
		}

	}

	.form-group {
		position: relative;

		select {
			-webkit-appearance: none;
		}

		button,
		.form-control {
			text-align: left;
			padding-left: 38px;
		}

		.fa,
		.fa-sharp {
			position: absolute;
			top: 19px;
			left: 22px;
			font-size: 1rem;
			pointer-events: none;

		}
	}

	#searchstart {
		position: relative;
		min-height: 98px;

		@media(max-width: 380px) {
			min-height: 145px;
		}
	}

	.share {
		position: absolute;
		top: 15px;
		right: 15px;
		font-size: var(--font-size-md);
		color: var(--color-primary);
		cursor: pointer;

		&:hover {
			color: var(--color-secondary);
		}
	}

	.dummyMap {
		width: 100px;
		height: 100px;
	}

	.search-title {
		margin-top: 16px;
	}

	.btn {
		text-decoration: none;

		&:hover {
			text-decoration: none;
		}
	}

	.results {
		display: grid;
		grid-template-columns: 1fr;
		grid-row-gap: 30px;
		margin-bottom: 30px;

		@media (min-width:992px) {
			grid-template-columns: 1fr 1fr;
			column-gap: 30px;
		}

		@media (max-width:991px) {
			grid-template-columns: 1fr;
		}

	}

	.more-btn-panel {
		margin-top: 30px;
	}

	.container,
	.container-xl {

		@media (min-width:1500px) {
			max-width: 1370px;
		}
	}

	.close {
		float: right;
		font-size: 1.5rem;
		font-weight: 700;
		line-height: 0.8;
		color: #000;
		text-shadow: 0 1px 0 #fff;
		opacity: .5;
		border: none;
		background: transparent;

		&:hover {
			opacity: .8;
		}
	}


}

.my-search-token {
	margin: 2px;
	padding: 2px 6px;
	display: inline-block;
	border: 1px solid var(--color-grey-normal);
	border-radius: 4px;
	background-color: var(--color-grey-light);
	font-size: 14px;
	cursor: pointer;
	position: relative;
	transition: all 0.5s ease;
	margin-right: 8px;

	.close {
		position: absolute;
		height: 13px;
		width: 13px;
		right: -7px;
		top: -7px;
		text-align: center;
		background-color: #ff9a00;
		background-color: var(--color-primary);
		color: #fff;
		border: 1px solid var(--color-grey-light);
		border-radius: 50%;
		font-weight: 400;
		opacity: 0;
		padding: 0;

		div {
			margin-top: -6px;
		}
	}

	&:hover {
		border: 1px solid var(--color-primary);
		background-color: var(--color-white);

		.close {
			opacity: 1;
		}
	}
}

.photo-grid {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.photo {
	width: 49%;
	margin-bottom: 20px;

	@media(max-width: 768px) {
		width: 99%;
	}

	img {
		width: 100%;
		height: auto;
	}
}