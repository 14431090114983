.footer-v1 {
	.main-footer {
		background-color: #cfb479;
		display: block;
		position: relative;
		font-family: var(--font-family-main);
		font-size: 15px;
		letter-spacing: 0;
		line-height: 26px;
		font-weight: 400;
		text-transform: none;
		font-style: normal;
		color: #8d8d8d;
		padding-top: 80px;
		padding-bottom: 80px;
		padding: 72px 0 52px;

		.col-lg-3 {
			@media(max-width:992px) {
				margin-bottom: 40px;
			}
		}

		.ft-logo {
			width: 180px;
			background: #fff;
			padding: 12px;
			border-radius: 8px;
		}


		p {
			margin: 0;
		}

		a {
			font-family: var(--font-family-main);
			font-size: 12px;
			letter-spacing: 1px;
			line-height: 26px;
			font-weight: 600;
			color: var(--color-white);

			&:hover {
				color: #000000;
				text-decoration: none;
			}
		}

		.social-icons {
			margin-top: 38px;

			a {
				.fa-brands {
					color: var(--color-white);
					margin-right: 5px;
					font-size: 25px;

					&:hover {
						color: #000000;
						text-decoration: none;
					}
				}
			}
		}

		.textwidget {
			font-family: var(--font-family-main);
			font-size: 15px;
			letter-spacing: 0;
			line-height: 26px;
			font-weight: 400;
			text-transform: none;
			font-style: normal;
			color: #fff;
		}

		.headline {
			font-family: var(--font-family-main);
			font-size: 23px;
			letter-spacing: 0.5px;
			line-height: 36px;
			font-weight: 400;
			text-transform: none;
			color: #ffffff;
			font-style: normal;
			margin: 0 0 22px;

		}
	}
}




/*.footer-v1 {


	.main-footer {
		.container-xl {
			padding: 80px 0;
		}

		background-color: var(--footer-bg-color);
		color: var(--footer-font-color);
		border-top: 1px solid var(--footer-border-top-color);

		.text-muted {
			color: var(--color-grey-normal) !important;

		}

		padding-left: 5vw;
		padding-right: 5vw;
		position: relative;

		.headline {
			font-size: 20px;
			color: var(--color-white);
			text-transform: uppercase;
			font-family: var(--title-font-family-main);
		}


		.logo {
			width: 250px;
			height: auto;
			margin-top: -40px;
			padding-bottom: 20px;
		}

		.v-office-logo {

			background-color: var(--color-secondary);
			padding: 10px 20px;

			@media(min-width: 993px) {
				text-align: right;
				padding: 10px 60px;
			}

			@media(max-width: 992px) {
				text-align: left;
			}
		}

		.openings {

			div {
				padding-top: 10px;
			}
		}

		a {
			color: var(--footer-font-color);
		}

		.social-icons {

			li {

				a {
					color: var(--footer-font-color);
				}

				.fa,
				.fa-brands {
					font-size: 20px;
					color: var(--footer-font-color);

				}
			}

		}




		#button-addon1 {
			border-top-left-radius: 0px;
			border-bottom-left-radius: 0px;
		}

		.btn-link {
			background-color: var(--footer-btn-bg-color);

			.fa,
			.fa-brands {
				color: var(--footer-icon-color);
			}
		}

		i {
			color: var(--color-white);
		}

		.form-control::placeholder {
			font-size: 0.95rem;
			color: #aaa;
			font-style: italic;
		}

		.form-control.shadow-0:focus {
			box-shadow: none;
		}

		.paymentmethods {
			width: 100%;
			text-align: right;
			margin-top: 30px;

			.fa,
			.fa-brands {
				color: var(--color-white);
				font-size: 1.8rem;
				padding-left: 10px;

			}
		}
	}


}*/