.familienurlaub-v1 {
	margin-top: var(--page-margin-top);

	.inner-banner {
		background-image: url(RESOURCE/img/banner-family2b.webp);
	}

	.img {
		width: 100%;
		height: auto;
		object-fit: cover;
	}
}

.familienurlaub-unit {
	.unit-list-section {
		padding: 0 30px;

		@media (max-width: 767px) {
			padding: 0;
		}

		.single-unit {
			box-shadow: none !important;
		}

		.unit-card-v1 {
			margin-bottom:  !important;

			.img-col {
				padding-top: 0;
			}

			.flex-middle {
				@media (min-width: 768px) {
					min-height: 82px;
				}
			}
		}
	}
}