.ueber-uns-v1 {
	margin-top: var(--page-margin-top);

	.item {
		background-color: #fff;
		/*	border: 1px solid var(--color-grey-normal);*/
		transition: all .2s;
		height: 100%;
		border-radius: var(--border-radius);

		.team-img {
			width: 300px;
			height: 300px;
			overflow: hidden;
			border-radius: 50%;
			display: table;
			margin: 0 auto;
			border: 8px solid var(--color-primary);

			@media (max-width:992px) {
				width: 200px;
				height: 200px;
			}

			img {
				height: 100%;
				width: 100%;
				object-fit: cover;
			}
		}

		/*&:hover {
			box-shadow: 0 0 8px 0 rgb(0 0 0 / 20%);
			transform: scale(1.015);
		}*/

		.team-content {
			padding: 10px;
			text-align: center;
		}
	}

	.benefit {
		h2 {
			margin: 50px 0;
		}
	}

	.benefit-item {
		background-color: #fff;
		border: 1px solid var(--color-grey-normal);
		transition: all .2s;
		height: 100%;
		border-radius: var(--border-radius);
		margin-top: 25px;

		/*&:hover {
			box-shadow: 0 0 8px 0 rgb(0 0 0 / 20%);
			transform: scale(1.015);
		}*/
		.benefit-img {
			text-align: center;
			max-width: 50px;
			margin: 0 auto;
			padding: 20px 0 0;
		}

		/*.benefit-img {
			text-align: center;
			height: 200px;
			width: 200px;
			overflow: hidden;
			border-radius: 50%;
			border: 8px solid #ceb479;
			border: 8px solid var(--color-primary);
			margin: 0 auto;
			padding: 40px;

			@media (max-width:992px) {
				height: 100px;
				width: 100px;
				border: 4px solid var(--color-primary);
			}


		}*/

		.benefit-content {
			padding: 10px;
			text-align: center;
		}
	}

	.social-icons {
		margin: 0;

		i {
			color: var(--color-black);
		}
	}


	.team-members {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(min(var(--about-us-grid-width), 100%), 1fr));
		grid-gap: 1em;
	}

	.member-info {
		text-align: center;
		background-color: var(--color-white);

		.name {
			font-weight: 600;
		}

		.resort {
			font-size: var(--font-size-md);
			color: var(--color-grey-dark);
			margin-bottom: 10px;
		}

		.phone {
			font-size: var(--font-size-md);
		}

		.email {
			font-size: var(--font-size-md);
		}

	}

	.social-icons {
		li {
			a {
				font-size: 30px;
			}
		}
	}
}