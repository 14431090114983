/* barlow-semi-condensed-regular - latin */
@font-face {
	font-family: 'Barlow Semi Condensed';
	font-style: normal;
	font-weight: 400;
	src: local(''),
		url('https://r.v-office.com/fonts/barlow-semi-condensed-v14-latin-regular.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('https://r.v-office.com/fonts/barlow-semi-condensed-v14-latin-regular.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	font-display: swap;
}


@font-face {
	font-family: 'Playfair Display';
	font-style: normal;
	font-weight: 400;
	src: local(''),
		url('https://r.v-office.com/fonts/playfair-display-v29-latin-regular.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('https://r.v-office.com/fonts/playfair-display-v29-latin-regular.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	font-display: swap;
}

@font-face {
	font-family: 'Nexa';
	src: url('RESOURCE/fonts/NexaBold.eot');
	src: url('RESOURCE/fonts/NexaBold.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/NexaBold.woff2') format('woff2'),
		url('RESOURCE/fonts/NexaBold.woff') format('woff'),
		url('RESOURCE/fonts/NexaBold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Nexa';
	src: url('RESOURCE/fonts/NexaLight.eot');
	src: url('RESOURCE/fonts/NexaLight.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/NexaLight.woff2') format('woff2'),
		url('RESOURCE/fonts/NexaLight.woff') format('woff'),
		url('RESOURCE/fonts/NexaLight.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}